import React, {type ReactElement, useState, useEffect, useRef} from 'react';
import type ViewReplayPopUpProps from './ArgumentTypes/ViewReplayPopUpProps';
import styles from '../resources/styles/ComponentSpecific/PopUp.module.css';
import stylesSources from '../resources/styles/ComponentSpecific/Replay.module.css';
import buttons from '../resources/styles/ElementSpecific/Buttons.module.css';
import {createState} from 'state-pool';
import {useWebSocket} from '../api/AGWContext';
import VisualizationService from '../api/VisualizationService';
import JSZip from 'jszip';
import {saveAs} from 'file-saver';
import DayCurveContainer from './containers/DayCurveContainer';

const replayData = createState<Array<[string, string, string]>>([
	['Waiting for replay', 'Waiting for replay', 'Waiting for replay'],
]);
const metadata = createState<Record<string, unknown>>({});

/**
 * Set the global state of the replayData variable to the input value.
 *
 * @param newReplayData - New Replay data to be visualized.
 */
export function setReplayData(
	newReplayData: Array<[string, string, string]>,
): void {
	replayData.setValue(newReplayData);
}

/**
 *
 * @param props - The input parameters passed to the component.
 *
 * @returns A React element containing the elements of the pop-up window used for adding a geofence.
 */
export default function ViewReplayPopUpContents(
	props: ViewReplayPopUpProps,
): ReactElement {
	const {ws, isConnected} = useWebSocket();
	useEffect(() => {
		if (!isConnected) {
			console.error('Websocket is not connected');
		}
	}, [isConnected]);
	const [replayDataValue] = replayData.useState();
	const [currentFrameIndex, setCurrentFrameIndex] = useState(0);
	const [isPlaying, setIsPlaying] = useState(false);
	const [playbackSpeed, setPlaybackSpeed] = useState(5);
	const playbackInterval = useRef<NodeJS.Timeout | undefined>(undefined);
	const [jumpTime, setJumpTime] = useState('00:00:00');
	const [fetchData, setFetchData] = useState(false);
	const [stepSize, setStepSize] = useState(1);
	const [metadataValue] = metadata.useState();
	const [viewDayCurve, setViewDayCurve] = useState(false);

	useEffect(() => {
		if (ws && props.selectedRecording) {
			const visualizationService = new VisualizationService(ws);
			visualizationService.requestRecording(
				props.selectedRecording?.name,
			);
		}
	}, [props.selectedRecording, ws]);

	useEffect(() => {
		if (ws && props.selectedRecording && fetchData) {
			const visualizationService = new VisualizationService(ws);
			visualizationService.requestRecording(
				props.selectedRecording?.name,
			);
			setFetchData(false);
		}
	}, [fetchData, props.selectedRecording, ws]);

	useEffect(() => {
		if (isPlaying) {
			playbackInterval.current = setInterval(() => {
				setCurrentFrameIndex(prevFrame =>
					prevFrame + 1 < replayDataValue.length ? prevFrame + 1 : 0,
				);
			}, playbackSpeed * 1000);
		} else {
			clearInterval(playbackInterval.current);
		}

		return () => {
			clearInterval(playbackInterval.current);
		};
	}, [isPlaying, playbackSpeed, replayDataValue.length]);

	useEffect(() => {
		if (replayDataValue.length > 0) {
			fetchMetadata(replayDataValue[currentFrameIndex][1]).catch(
				error => {
					console.error('Failed to fetch metadata:', error);
				},
			);
		}
	}, [currentFrameIndex, replayDataValue]);

	// Handler for changing playback speed in replay.
	const handlePlaybackSpeedChange = (
		event: React.ChangeEvent<HTMLInputElement>,
	): void => {
		setPlaybackSpeed(parseFloat(event.target.value));
	};

	// Handler for changing the jump time in replay.
	const handleJumpTimeChange = (
		event: React.ChangeEvent<HTMLInputElement>,
	): void => {
		setJumpTime(event.target.value);
	};

	// Handler for submitting the jump time, which updates the current frame index to the time specified.
	const handleJumpTimeSubmit = (): void => {
		const newFrameIndex = replayDataValue.findIndex(
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			([_, timestamp]) => jumpTime <= timestamp,
		);
		setCurrentFrameIndex(
			newFrameIndex >= 0 ? newFrameIndex : replayDataValue.length - 1,
		);
	};

	// Handler for jumping to the start of the replay.
	const handleJumpToStart = (): void => {
		setCurrentFrameIndex(0);
	};

	// Handler for jumping to the end of the replay.
	const handleJumpToEnd = (): void => {
		setCurrentFrameIndex(replayDataValue.length - 1);
	};

	// Handler for changing the step size in replay.
	const handleStepSizeChange = (
		event: React.ChangeEvent<HTMLInputElement>,
	): void => {
		setStepSize(Number(event.target.value));
	};

	// Handler for moving to the next frame based on step size.
	const handleNextFrame = (): void => {
		setCurrentFrameIndex(prev =>
			Math.min(prev + stepSize, replayDataValue.length - 1),
		);
	};

	// Handler for moving to the previous frame based on step size.
	const handlePreviousFrame = (): void => {
		setCurrentFrameIndex(prev => Math.max(prev - stepSize, 0));
	};

	// Handler for downloading all frames of the replay as a zip file.
	const downloadAll = async (): Promise<void> => {
		const zip = new JSZip();

		const folder = zip.folder('images');

		if (!folder) {
			throw new Error('Unable to create folder');
		}

		const fetchPromises = replayDataValue.map(async (value, i) => {
			const urlImage = value[0];
			const visual = await fetch(urlImage);
			const blob = await visual.blob();

			const urlData = value[1];
			const response = await fetch(urlData);
			const data = (await response.json()) as Record<string, number>;

			folder.file(`image${i}_${data.people}.jpg`, blob, {binary: true});
		});

		await Promise.all(fetchPromises);

		zip.generateAsync({type: 'blob'})
			.then((content: Blob) => {
				saveAs(content, 'replay_images.zip');
			})
			.catch((error: Error) => {
				console.error('An error occurred:', error);
			});
	};

	/**
	 * Function for fetching the metadata of a replay frame.
	 *
	 * @param url - Url for the metadata.
	 */
	async function fetchMetadata(url: string): Promise<void> {
		const response = await fetch(url);
		const data = (await response.json()) as Record<string, unknown>;
		metadata.setValue(data);
	}

	if (replayDataValue.length === 0) {
		return (
			<div
				className={styles.Window}
				data-testid='ViewReplayPopUpContents'
			>
				<p>Recording Name: {props.selectedRecording?.name}</p>
				<p>
					Stream Name: {props.selectedRecording?.livestreamName} with
					Technique: {props.selectedRecording?.technique}
				</p>
				<p>
					Designated Start Time:{' '}
					{props.selectedRecording?.startDateTime} and End Time:{' '}
					{props.selectedRecording?.endDateTime}
				</p>
				<br/>
				<p>No data available for this recording yet.</p>
				<div>
					<button
						type='button'
						id='view'
						className={buttons.Button}
						onClick={() => {
							setFetchData(true);
						}}
					>
						Fetch Data
					</button>
					<button
						className={buttons.Button}
						id='Close'
						type='button'
						onClick={props.onCloseProp}
					>
						Close
					</button>
				</div>
			</div>
		);
	}

	return (
		<div className={styles.Window} data-testid='ViewReplayPopUpContents'>
			<div className={styles.ImageDiv}>
				<img
					id='ReplayImage'
					className={stylesSources.Image}
					src={replayDataValue[currentFrameIndex][0]}
					alt='frame'
				/>

				<input
					type='range'
					min='0'
					max={replayDataValue.length - 1}
					value={currentFrameIndex}
					className={styles.InputRange}
					onChange={event => {
						setCurrentFrameIndex(Number(event.target.value));
					}}
				/>
			</div>
			<div className={styles.VideoKit}>
				<button
					type='button'
					className={buttons.Button}
					onClick={handleJumpToEnd}
				>
					Last
				</button>
				<button
					type='button'
					className={buttons.Button}
					disabled={currentFrameIndex === replayDataValue.length - 1}
					onClick={handleNextFrame}
				>
					Next
				</button>
				<button
					type='button'
					className={buttons.Button}
					disabled={!isPlaying}
					onClick={() => {
						setIsPlaying(false);
					}}
				>
					Pause
				</button>
				<button
					type='button'
					className={buttons.Button}
					disabled={isPlaying}
					onClick={() => {
						setIsPlaying(true);
					}}
				>
					Play
				</button>
				<button
					type='button'
					className={buttons.Button}
					disabled={currentFrameIndex === 0}
					onClick={handlePreviousFrame}
				>
					Previous
				</button>
				<button
					type='button'
					className={buttons.Button}
					onClick={handleJumpToStart}
				>
					First
				</button>
			</div>
			<p className={styles.Data}>
				Frame: {currentFrameIndex + 1}/{replayDataValue.length} at time:{' '}
				{replayDataValue[currentFrameIndex][2]}
			</p>
			<div className={styles.LabelTextDiv}>
				<label>Step Size (Frames):</label>
				<input
					type='number'
					min='1'
					value={stepSize}
					className={`${styles.Input} ${styles.ViewInput}`}
					onChange={handleStepSizeChange}
				/>
			</div>
			<div className={styles.LabelTextDiv}>
				<label>Playback Speed (seconds):</label>
				<input
					type='number'
					min='0'
					value={playbackSpeed}
					className={`${styles.Input} ${styles.ViewInput}`}
					onChange={handlePlaybackSpeedChange}
				/>
			</div>
			<div className={styles.LabelTextDiv}>
				<label>Jump to Time (HH:MM:SS):</label>
				<input
					type='text'
					value={jumpTime}
					className={`${styles.Input} ${styles.ViewInput}`}
					onChange={handleJumpTimeChange}
				/>
				<button
					type='button'
					className={buttons.Button}
					onClick={handleJumpTimeSubmit}
				>
					Go
				</button>
			</div>
			<div className={styles.ButtonsDiv}>
				<button
					className={buttons.Button}
					id='Close'
					type='button'
					onClick={props.onCloseProp}
				>
					Close
				</button>
				<button
					type='button'
					className={buttons.Button}
					onClick={() => {
						setFetchData(true);
					}}
				>
					Fetch Data
				</button>
				<button
					type='button'
					disabled={props.selectedRecording?.technique !== 'CROWD_COUNTING'}
					className={buttons.Button}
					id='DayCurve'
					onClick={() => {
						setViewDayCurve(true);
					}}
				>
					Day-Curve
				</button>
			</div>
			<div className={styles.ResultsDiv}>
				{Object.entries(metadataValue).map(([key, value]) => {
					if (key === 'counts') {
						const counts = value as Record<string, number>;
						return Object.entries(counts).map(
							([vehicle, count]) => (
								<p
									key={`${key}-${vehicle}`}
								>{`${vehicle}: ${count}`}
								</p>
							),
						);
					}

					if (key === 'speed_data') {
						const speedData = value as Array<{
							id: string;
							speed: number;
						}>;
						// Map through each entry in the 'speedData' array and display the id and its speed
						return speedData.map(({id, speed}) => (
							<p
								key={`${key}-${id}`}
							>{`ID: ${id} Speed: ${speed}km/h`}
							</p>
						));
					}

					if (key === 'people') {
						return (
							<p key={key}>There are {String(value)} people</p>
						);
					}

					if (key === 'url') {
						return <p key={key}>{`${key}: ${String(value)}`}</p>;
					}

					return null;
				})}
			</div>
			<button
				type='button'
				className={buttons.Button}
				id='Download'
				onClick={downloadAll}
			>
				Download All
			</button>
			{viewDayCurve && (
				<DayCurveContainer
					data={replayDataValue}
					title='Day-Curve'
					set={setCurrentFrameIndex}
					yAxisLabel='People'
				/>
			)}
		</div>
	);
}
