import React, {type ReactElement, useState, useEffect} from 'react';
import {type SetGeoFenceProps} from './ArgumentTypes/CreateGeoFenceProps';
import {createState} from 'state-pool';
import VisualizationContainer from './containers/VisualizationContainer';
import styles from '../resources/styles/ComponentSpecific/PopUp.module.css';
import buttons from '../resources/styles/ElementSpecific/Buttons.module.css';
import {useWebSocket} from '../api/AGWContext';
import VideoService from '../api/VideoService';

const width = createState<number>(1);
const height = createState<number>(1);
const imageSourceGeoFence = createState<Record<string, string>>({});

/**
 * Set the global state of the width variable to the input value .
 *
 * @param w - The inputted width of the image.
 */
export function setWidthGeo(w: number): void {
	width.setValue(w);
}

/**
 *Adds an image to the global state for geo-fence drawing.
 *
 * @param streamName - Name of stream to add geo fence image for.
 * @param im - The image to display.
 */
export function addGeoFenceImage(streamName: string, im: string): void {
	imageSourceGeoFence.setValue(prevState => ({
		...prevState,
		[streamName]: im,
	}));
}

/**
 * Set the global state of the height variable to the input value .
 *
 * @param h - The inputted height of the image.
 */
export function setHeightGeo(h: number): void {
	height.setValue(h);
}

/**
 *
 * @param props - The input parameters passed to the component.
 *
 * @returns A React element containing the elements of the pop-up window used for adding a geofence.
 */
export default function GeoFencePopUpContents(
	props: SetGeoFenceProps,
): ReactElement {
	const {ws, isConnected} = useWebSocket();
	useEffect(() => {
		if (!isConnected) {
			console.error('Websocket is not connected');
		}
	}, [isConnected]);
	const [geoCoordinates, setGeoCoordinates] = useState<number[][]>([]);
	const [length, setLength] = useState<GLfloat>(-1.0);
	const [lengthError, setLengthError] = useState('');
	const [pointsError, setPointsError] = useState('');
	const [imWidth] = width.useState();
	const [imHeight] = height.useState();
	const [imSourceMap] = imageSourceGeoFence.useState();
	const imSource = imSourceMap[props.sourceName];

	/**
	 * This function stores the coordinates where the user has drawn dots on the canvas.
	 * @param x - The x-coordinate of the point.
	 * @param y - The y-coordinate of the point.
	 */
	function extractDot(x: number, y: number): void {
		setGeoCoordinates([...geoCoordinates, [x, y]]);
	}

	/**
	 * When the user wants to submit a geo-fence, the validity of inputs is checked.
	 * The validation checks are: the length of the geo-fence must be positive and
	 * the coordinates of the geo-fence must be 4.
	 * If valid, the inputs are passed to a handler so that they can be sent to the backend.
	 *
	 * @param event - The event triggered when clicking the button.
	 * @param onClose - The function responsible or closing the modal.
	 */
	function handleSubmit(
		event: React.MouseEvent<HTMLButtonElement>,
		onClose: () => void,
	): void {
		event.preventDefault(); // Prevents refresh on submit

		if (!ws) {
			throw new Error('Websocket not connected');
		} else if (length <= 0) {
			setLengthError('Not a valid length.');
		} else if (geoCoordinates.length === 4) {
			setPointsError('');
			const videoService = new VideoService(ws);
			videoService.addGeoFence(props.sourceName, geoCoordinates, length);
			props.setGeoFenceFlag(false);
			onClose();
		} else {
			setPointsError('Not enough points');
		}
	}

	/**
	 * This handler is called when the user inputs the length of the geo-fence.
	 * It also ensures all validation checks specified in the component and adhered to.
	 *
	 * @param e - The event triggered when changing the length input.
	 */
	function handleLength(e: React.ChangeEvent<HTMLInputElement>): void {
		if (e.target.validity.valid) {
			setLength(parseInt(e.target.value, 10));
			setLengthError('');
		} else {
			setLengthError('Not a valid length.');
		}
	}

	/**
	 * Clear all points drawn on the canvas.
	 */
	const handleClear = (): void => {
		setGeoCoordinates([]);
	};

	return (
		<div className={styles.Window}>
			<p className={styles.Instructions}>
				Please draw 4 points that will represent a rectangle - this will
				be the geo-fence. The first two points must outline the longest
				side.
			</p>
			<p className={styles.Text}>
				Length of the longest side(in meters):
			</p>
			<input
				required
				className={styles.Input}
				type='number'
				min={1}
				onChange={e => {
					handleLength(e);
				}}
			/>
			<p className={styles.Error}>{lengthError}</p>
			<VisualizationContainer
				label='Waiting for image'
				dotsToDraw={geoCoordinates}
				imWidth={imWidth}
				imHeight={imHeight}
				imageSource={imSource}
				styleId='geofenceVisual'
				onMouseDownFunction={(x: number, y: number) => {
					extractDot(x, y);
				}}
			/>
			<p className={styles.Error}>{pointsError}</p>
			<button
				className={buttons.Button}
				id='Clear'
				type='reset'
				onClick={handleClear}
			>
				Clear
			</button>
			<br/>
			<div className={styles.ButtonsDiv}>
				<button
					className={buttons.Button}
					id='Close'
					type='button'
					onClick={props.onClose}
				>
					Close
				</button>
				<button
					className={buttons.Button}
					type='submit'
					onClick={e => {
						handleSubmit(e, props.onClose);
					}}
				>
					Submit
				</button>
			</div>
		</div>
	);
}
