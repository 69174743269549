import React, {
	useState,
	useEffect,
	useContext,
	createContext,
	type ReactElement,
} from 'react';
import ApiGatewaySocket from './server';
import {setError} from '../App';
type WebSocketContextType = {
	ws: ApiGatewaySocket | undefined;
	isConnected: boolean;
};

const WebSocketContext = createContext<WebSocketContextType>({
	ws: undefined,
	isConnected: false,
});

type WebSocketProviderProps = Readonly<{
	url: string;
	children: React.ReactNode;
}>;

/**
 * Provides the entire application with a connection to the backend.
 * Wrap around App and call useWebSocket() to get access to the server.
 *
 * @param param0 - Contains url to server and child components with access to the server.
 * @param param0.url - Url for websocket connection.
 * @param param0.children - Children of this component.
 * @returns Component which contains a websocket connection in its context.
 */
function WebSocketProvider({
	url,
	children,
}: WebSocketProviderProps): ReactElement {
	const [ws, setWs] = useState<ApiGatewaySocket | undefined>(undefined);
	const [isConnected, setIsConnected] = useState(false);
	const [errorThrown, setErrorThrown] = useState(false);

	useEffect(() => {
		let interval: NodeJS.Timeout | undefined;
		try {
			const ws = new ApiGatewaySocket(url);
			console.log('Attempting to connect to server at ' + url);
			setWs(ws);
			setIsConnected(true);

			interval = setInterval(() => {
				if (ws.socket?.readyState === WebSocket.OPEN) {
					setIsConnected(true);
				} else {
					setIsConnected(false);
				}
			}, 1000);

			return () => {
				if (interval) {
					clearInterval(interval);
					ws.socket?.close();
				}
			};
		} catch (error) {
			console.error('Failed to connect to WebSocket server:', error);
			setErrorThrown(true);
		}
	}, [url]);

	if (errorThrown) {
		setError('Failed to connect to server', 'Please try again');
	}

	return (
	// eslint-disable-next-line react/jsx-no-constructed-context-values
		<WebSocketContext.Provider value={{ws, isConnected}}>
			{children}
		</WebSocketContext.Provider>
	);
}

/**
 * Get instance of websocket connection to backend. Recalling this function in the same provider should give the same connection.
 *
 * @returns The AGWSocket instance.
 */
function useWebSocket(): WebSocketContextType {
	const ws = useContext(WebSocketContext);
	if (!ws) {
		throw new Error('useWebSocket must be used within a WebSocketProvider');
	}

	return ws;
}

export {WebSocketProvider, useWebSocket};
