import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {WebSocketProvider} from './api/AGWContext';

const root: ReactDOM.Root = ReactDOM.createRoot(
	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	document.getElementById('root')!,
);

console.log(process.env);

root.render(
	<WebSocketProvider
		url={
			process.env.REACT_APP_API_GATEWAY_WS_URL
            ?? 'wss://vas.scenwise.nl/api-gateway'
		}
	>
		<App/>
	</WebSocketProvider>,
);
