import React, {type ReactElement} from 'react';
import {useWebSocket} from '../api/AGWContext';
import logo from '../resources/images/scenwise-logo.png';
import styles from '../resources/styles/ComponentSpecific/Header.module.css';

/**
 * React component containing elements of header including Scenwise logo and title.
 *
 * @returns React component containing header elements.
 */
function HeaderContents(): ReactElement {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const {ws, isConnected} = useWebSocket();
	return (
		<div className={styles.header}>
			<div>
				<img
					id='logo'
					className={styles.img}
					alt='Scenwise Logo'
					src={logo}
				/>
			</div>
			<div>
				<h1 className={styles.title}> Video Analytics Service</h1>
			</div>
			<div className={styles.serverStatus}>
				Server Status:{' '}
				<span
					className={isConnected ? styles.connected : styles.disconnected}
				>
					{isConnected ? 'Connected' : 'Disconnected'}
				</span>
			</div>
		</div>
	);
}

export default HeaderContents;
