/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/naming-convention */

import {type Message} from './model/messages';
import {
	setAnalysisResult,
	setOriginalImage,
	setValidationImage,
	setHeight,
	setWidth,
} from '../components/AnalysisResultsContents';
import {type SendVisualBody} from '../Types/VisualizationBody';
import {type SendReplayBody} from '../Types/ReplayBody';
import {setReplayData} from '../components/ViewReplayPopUpContents';
import {setUrl} from '../components/DownloadButtonContents';

/**
 * Handle a message from the visualization service.
 *
 * @param message - The message received.
 */
export default function handleVisualization(message: Message): void {
	const {body} = message.payload;
	switch (message.payload.payload_type) {
		case 'SEND_VISUAL': {
			if (body satisfies SendVisualBody) {
				const {width, height, meta_data, original_frame, visual_frame}
                    = body; // Replace with actual width of image

				setValidationImage(visual_frame);
				setOriginalImage(original_frame);
				setHeight(height);
				setWidth(width);
				setAnalysisResult(meta_data);
			} else {
				throw Error(
					'Expected message body to have width and height attributes',
				);
			}

			break;
		}

		case 'DOWNLOAD_READY': {
			const {

				presigned_url,
			} = body;
			setUrl(presigned_url);
			break;
		}

		case 'SEND_REPLAY_DATA': {
			if (!(body satisfies SendReplayBody)) {
				throw Error(
					'Expected message body to be a list of urls and dates',
				);
			}

			setReplayData(body);
			break;
		}

		default: {
			throw Error('Unexpected payload type');
		}
	}
}
