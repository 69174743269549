import {type Message} from './model/messages';
import {handleAgwMessage} from './AGWHandlers';
import handleVisualizationMessage from './VisualizationHandlers';
import handleVideoServiceMessage from './VideoServiceHandlers';
import {setError} from '../App';
import handleComputerVisionMessage from './ComputerVisionHandler';
const validRecipients = [
	'API_GATEWAY',
	'VIDEO_SERVICE',
	'VISUALIZATION_SERVICE',
];

export default class ApiGatewaySocket {
	clientId = 'ANONYMOUS_CLIENT';
	socket: WebSocket | undefined = undefined;

	initialConnectionDelay = 1000;
	currentConnectionDelay = this.initialConnectionDelay;
	maxConnectionDelay = 16000;

	/**
	 * Creates an instance of this class.
	 *
	 * @param url - The url to connect to.
	 */
	constructor(public url: string) {
		this.url = url;
		this.socket = this.connect(url);
	}

	/**
	 * Function to connect to the server.
	 *
	 * @param url - The url to connect to.
	 * @returns The websocket connection.
	 */
	connect(url: string): WebSocket {
		const socket = new WebSocket(url);
		socket.addEventListener('open', () => {
			this.currentConnectionDelay = this.initialConnectionDelay;
			console.log('Connected to server');
		});
		socket.addEventListener('error', this.onWebsocketClose.bind(this));
		socket.addEventListener('close', this.onWebsocketClose.bind(this));
		socket.addEventListener('message', this.onMessage.bind(this));
		return socket;
	}

	/**
	 * Function to handle closing of the websocket connection. Tries to reconnect
	 * after a delay.
	 *
	 * @param event - Event which led to closing of connection.
	 */
	onWebsocketClose(event: Event): void {
		console.error(event);
		if (this.socket) {
			this.socket.close();
			this.socket = undefined;
		}

		setTimeout(() => {
			this.reconnect();
		}, this.currentConnectionDelay + Math.floor(Math.random() * 3000));
	}

	/**
	 * Function to reconnect to the server. Increases the delay between each
	 * attempt to reconnect.
	 */
	reconnect(): void {
		if (this.currentConnectionDelay < this.maxConnectionDelay) {
			this.currentConnectionDelay *= 2;
		}

		this.socket = this.connect(this.url);
	}

	/**
	 * Function to set the clientId of an APIGatewaySocket to a new value.
	 *
	 * @param newId - The new id.
	 */
	setClientId(newId: string): void {
		console.log('Setting client id to: ' + newId);
		this.clientId = newId;
	}

	/**
	 * Function to get the clientId of an APIGatewaySocket.
	 *
	 * @returns The clientId of the socket.
	 */
	getClientId(): string {
		return this.clientId;
	}

	/**
	 * Send a message to the backend through the api_gateway. Reject messages with an unknown recipient.
	 *
	 * @param payload - The payload of the message.
	 * @param recipient - The recipient of the message.
	 */
	sendMessage(payload: any, recipient: string): void {
		if (!validRecipients.includes(recipient)) {
			throw Error('Invalid recipient: ' + recipient);
		}

		const message: Message = {
			sender: this.clientId,
			recipient,
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			payload,
		};

		this.socket?.send(JSON.stringify(message));
	}

	/**
	 * Function which gets called on closing of the websocket connection.
	 *
	 * @param _event - Event which led to closing of connection.
	 */
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	onClose(_event: Event): void {
		setError('Server connection closed', 'Please try again later');
	}

	/**
	 * Function which gets called on receiving an error over the connection.
	 *
	 * @param event - Event which led to the error.
	 */
	onError(event: Event): void {
		if (event.type === 'error') {
			setError('Could not connect to server', 'Please try again later');
		} else {
			setError(
				'Unknown Error',
				'We are doing our best to fix it! Feel free to contact us.',
			);
		}
	}

	/**
	 * Function which is called on receiving a message over the websocket connection.
	 * Calls the message handler corresponding to the sender of the message.
	 *
	 * @param event - The message event containing the message and metadata.
	 */
	onMessage(event: MessageEvent): void {
		const message = JSON.parse(
			event.data as unknown as string,
		) as unknown as Message;

		switch (message.sender) {
			case 'API_GATEWAY': {
				handleAgwMessage(message, this);
				break;
			}

			case 'VISUALIZATION_SERVICE': {
				handleVisualizationMessage(message);
				break;
			}

			case 'VIDEO_SERVICE': {
				handleVideoServiceMessage(message);
				break;
			}

			case 'COMPUTER_VISION': {
				handleComputerVisionMessage(message);
				break;
			}

			default: {
				console.error('Invalid message sender: ' + message.sender);
				throw Error('Invalid message sender');
			}
		}
	}
}
