import React, {type ReactElement} from 'react';
import {createState} from 'state-pool';
import type DownloadButtonProps from './ArgumentTypes/DownloadButtonProps';
const url = createState<string>('');
const livestreamName = createState<string>('');

/**
 * @param newUrl - The new URL to set the global state to.
 */
export function setUrl(newUrl: string): void {
	url.setValue(newUrl);
}

/**
 * @param newLivestreamName - The new name to set the global state to.
 */
export function setLivestreamNameForDownload(newLivestreamName: string): void {
	livestreamName.setValue(newLivestreamName);
}

/**
 * React component containing the download button.
 *
 * @param props - The input parameters for the component.
 * @returns React component containing the download button.
 */
export default function DownloadButtonContents(
	props: DownloadButtonProps,
): ReactElement {
	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const handleSubmit = async (): Promise<void> => {
		const apiUrl
            = process.env.REACT_APP_API_GATEWAY_HTTP_URL
            ?? 'https://vas.scenwise.nl';
		const endpointUrl = apiUrl + '/get-download-link';
		const params = new URLSearchParams({
			// eslint-disable-next-line @typescript-eslint/naming-convention
			stream_name: props.streamName,
			id: Date.now().toString(),
		});
		setIsLoading(true);
		try {
			const response = await fetch(
				`${endpointUrl}?${params.toString()}`,
				{
					method: 'GET',
				},
			);

			if (!response.ok) {
				throw new Error(
					'Network response was not ok, status: '
                    + response.status.toString()
                    + ' '
                    + response.statusText,
				);
			}

			const downloadUrl = await response.text();
			window.open(downloadUrl, '_blank');
		} catch (error) {
			console.log('Error fetching download link: ', error);
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<div>
			<button
				type='button'
				className='btn btn-primary'
				disabled={props.streamName === 'None' || isLoading}
				onClick={handleSubmit}
			>
				{isLoading ? 'Loading...' : 'Download Frames'}
			</button>
		</div>
	);
}
