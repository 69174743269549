import React, {type ReactElement, useState} from 'react';
import ReactModal from 'react-modal';
import styles from '../resources/styles/ComponentSpecific/MainBody.module.css';
import buttons from '../resources/styles/ElementSpecific/Buttons.module.css';
import containers from '../resources/styles/ElementSpecific/Container.module.css';
import SourceIndicator from './containers/SourceIndicatorContainer';
import SearchLivestream from './containers/SearchLivestreamContainer';
import SourcePopUpContainer from './containers/SourcePopUpContainer';
import DeletePopUpContainer from './containers/DeletePopUpContainer';
import {type SetSourcesProps} from './ArgumentTypes/SetSourcesProps';
import {useWebSocket} from '../api/AGWContext';

/**
 * React component containing elements of video sources component.
 *
 * @param props - The props of the component.
 * @returns React component containing elements of video sources component.
 */
function VideoSrcContents(props: SetSourcesProps): ReactElement {
	const [isOpen, setIsOpen] = useState(false);
	const [isDeleteOpen, setIsDeleteOpen] = useState(false);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const {ws, isConnected} = useWebSocket();

	return (
		<div>
			<h2 className={styles.SectionTitle}> Video Sources </h2>
			<div className={containers.Container}>
				<SourceIndicator sourceName={props.sourceName}/>
				<button
					type='button'
					id='AddSource'
					className={buttons.Button}
					disabled={!isConnected}
					onClick={() => {
						setIsOpen(true);
					}}
				>Add source
				</button>
				{/*
<button
					type='button'
					id='deleteSource'
					disabled={props.sourceName === 'None'}
					className={buttons.Button}
					onClick={() => {
						setIsDeleteOpen(true);
					}}
				>Delete Source
				</button>
<SearchLivestream
					{...props}
				/>
                */}
				<SearchLivestream {...props}/>

				<ReactModal
					className={styles.Modal}
					isOpen={isOpen}
					ariaHideApp={false}
					onRequestClose={() => {
						setIsOpen(false);
					}}
				>
					<SourcePopUpContainer
						setSourceName={props.setSourceName}
						setTechniques={props.setAnalysisTechniques}
						addStreamRequiringViewSelection={props.addStreamRequiringViewSelection}
						onCloseProp={() => {
							setIsOpen(false);
						}}/>
				</ReactModal>
				<ReactModal
					className={styles.Modal}
					isOpen={isDeleteOpen}
					ariaHideApp={false}
					onRequestClose={() => {
						setIsDeleteOpen(false);
					}}
				>
					<DeletePopUpContainer
						sourceName={props.sourceName}
						analysisTechniques={props.analysisTechniques}
						setSourceName={props.setSourceName}
						setAnalysisTechniques={props.setAnalysisTechniques}
						setSelected={props.setSelected}
						onCloseProp={() => {
							setIsDeleteOpen(false);
						}}/>
				</ReactModal>
			</div>
		</div>
	);
}

export default VideoSrcContents;
