import type ApiGatewaySocket from './server';
import {type Message} from './model/messages';
import {addNewStream, allStreamsState} from '../components/MainBodyContents';

type NewStreamBody = string;
type RespondClientBody = {
	client_id: string;
	current_streams: string[];
};

type Body = NewStreamBody | RespondClientBody;

/**
 * Handles message from api_gateway. Receives generated client_id from api_gateway and sets the socket's
 * clientId to this value.
 *
 * @param message - The message received.
 * @param socket - The websocket connection.
 */
export function handleAgwMessage(
	message: Message,
	socket: ApiGatewaySocket,
): void {
	const {payload} = message;

	const {body} = payload as {body: Body};

	switch (payload.payload_type) {
		case 'NEW_STREAM': {
			const newStreamBody = body as NewStreamBody;
			addNewStream(newStreamBody);
			break;
		}

		case 'RESPOND_CLIENT_ID':
			handleClientIdMessage(body as RespondClientBody, socket);
			break;
		case 'HEARTBEAT':
			// Do nothing
			break;
		default:
			throw Error('Invalid payload type');
	}
}

/**
 * Handler for message which sends a generated client id.
 *
 * @param body - The body of the message.
 * @param socket - The socket connecting to the api_gateway.
 */
export function handleClientIdMessage(
	body: RespondClientBody,
	socket: ApiGatewaySocket,
): void {
	if (body === undefined) {
		throw Error('Invalid body');
	}

	// eslint-disable-next-line @typescript-eslint/naming-convention
	const {client_id, current_streams} = body;
	if (typeof client_id !== 'string') {
		throw Error('Invalid client id');
	}

	if (!Array.isArray(current_streams)) {
		throw Error('Invalid current streams');
	}

	allStreamsState.setValue(['None', ...current_streams]);

	socket.setClientId(client_id);
}

export default handleAgwMessage;
