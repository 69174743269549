import React, {type ReactElement, useState} from 'react';
import VidSources from './containers/VideoSourcesContainer';
import AnalysisResults from './containers/AnalysisResultsContainer';
import styles from '../resources/styles/ComponentSpecific/MainBody.module.css';
import {type Technique} from '../Types/Technique';
import {createState, type State} from 'state-pool';
import ErrorAlertContents from './ErrorAlertContents';

const allStreamsState: State<string[]> = createState<string[]>(['']);
const sourceNameState: State<string> = createState<string>('None');

const streamTechniqueDictionary: State<Record<string, Technique[]>>
    = createState<Record<string, Technique[]>>({});
const errorAlert = createState<string | undefined>(undefined);

/**
 * Function for updating the dictionary for streams and accompanying techniques.
 *
 * @param newDictionary - New dictionary specifying source to techniques mapping.
 */
function updateTechniquesDictionary(
	newDictionary: Record<string, Technique[]>,
): void {
	streamTechniqueDictionary.setValue(newDictionary);
}

/**
 * Function for setting the error message to be shown in the alert.
 * @param errorMessage - The messsage to be displayed.
 */
function setErrorAlert(errorMessage: string): void {
	errorAlert.setValue(errorMessage);
}

/**
 * Function for adding a new stream to the list of all streams.
 * @param streamName - The name of the stream to be added.
 */
function addNewStream(streamName: string): void {
	allStreamsState.setValue(prevStreams => [...prevStreams, streamName]);
}

/**
 * This function returns the contents of the main body of the page, including the
 * Video Sources component, the Video Analytics Component, the Results component
 * and the visualization component.
 * @returns The ReactElement containing the contents of the MainBody component.
 */
function MainBodyContents(): ReactElement {
	const [sourceName, setSourceName] = sourceNameState.useState();
	const [selectedTechniques, setSelectedTechniques] = useState<Technique[]>(
		[],
	);
	const [selected, setSelected] = useState<Technique | undefined>(undefined);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [geoFenceFlag, setGeoFenceFlag] = useState(false);
	const [error, setError] = errorAlert.useState();
	const [streamsRequiringViewSelection, setStreamsRequiringViewSelection]
        = useState<Array<[string, string, string[], Record<string, string[]>]>>([]);

	const [allStreams, setAllStreams] = allStreamsState.useState();

	const addStreamRequiringViewSelection = (
		url: string,
		name: string,
		techniques: string[],
		techniqueObjectsToDetect: Record<string, string[]>,
	): void => {
		setStreamsRequiringViewSelection(prevStreams => [
			...prevStreams,
			[url, name, techniques, techniqueObjectsToDetect],
		]);
	};

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const getAndRemoveStreamRequiringViewByName = (
		name: string,
	): undefined | [string, string, string[], Record<string, string[]>] => {
		const stream = streamsRequiringViewSelection.find(
			([, streamName]) => streamName === name,
		);
		if (stream) {
			setStreamsRequiringViewSelection(prevStreams =>
				prevStreams.filter(([, streamName]) => streamName !== name),
			);
		}

		return stream;
	};

	/**
	 * Change the selected techniques.
	 *
	 * @param techniques - The techniques selected.
	 */
	const customSetSelectedTechniques = (techniques: Technique[]): void => {
		setSelectedTechniques(techniques);
		if (techniques.length === 0) {
			setSelected(undefined);
		}

		setGeoFenceFlag(false);
		techniques.forEach(x => {
			if (x.requiresGeoFence) {
				setGeoFenceFlag(true);
			}
		});
	};

	return (
		<div className={styles.Body} id='main_body'>
			{error && (
				<ErrorAlertContents message={error} resetError={setError}/>
			)}
			<VidSources
				sourceName={sourceName}
				analysisTechniques={selectedTechniques}
				setSourceName={setSourceName}
				setAnalysisTechniques={customSetSelectedTechniques}
				streamsRequiringViewSelection={streamsRequiringViewSelection}
				addStreamRequiringViewSelection={addStreamRequiringViewSelection}
				setSelected={setSelected}
				allStreams={allStreams}
				setAllStreams={setAllStreams}
			/>
			{/* <VidAnalytics
				sourceName={sourceName}
				analysisTechniques={selectedTechniques}
				geoFenceNeeded={geoFenceFlag}
				selectedStream={sourceName}
				selected={selected}
				setSelected={setSelected}
				setGeoFenceNeeded={setGeoFenceFlag}
				streamsRequiringViewSelection={streamsRequiringViewSelection}
				getAndRemoveStreamRequiringViewByName={getAndRemoveStreamRequiringViewByName}
				setAnalysisTechniques={customSetSelectedTechniques}
			/>
			<Replay/>
*/}
			<AnalysisResults selected={selected}/>
		</div>
	);
}

export default MainBodyContents;
export {streamTechniqueDictionary, updateTechniquesDictionary, setErrorAlert, addNewStream, allStreamsState, sourceNameState};
