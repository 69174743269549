/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {useEffect, type ReactElement} from 'react';
import {
	AbortMultipartUploadCommand,
	CompleteMultipartUploadCommand,
	CreateMultipartUploadCommand,
	PutObjectCommand,
	S3,
	UploadPartCommand,
} from '@aws-sdk/client-s3';
// Import {
// Reimport later: getSignedUrl,
// Reimport later:S3RequestPresigner,
// Reimport later:} from "@aws-sdk/s3-request-presigner";
import {useState} from 'react';
import styles from '../resources/styles/ComponentSpecific/PopUp.module.css';
import buttons from '../resources/styles/ElementSpecific/Buttons.module.css';
// Reimport later:import ChecklistContainer from './containers/ChecklistContainer';
// Reimport later: import ObjectsDetectContainer from './containers/ObjectsDetectChecklistContainer';
import type Props from './ArgumentTypes/PopUpProps';
import {useWebSocket} from '../api/AGWContext';
import VideoService from '../api/VideoService';
import {
	type Technique,
	techniqueList,
	// DetectableObjects,
} from '../Types/Technique';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faQuestionCircle} from '@fortawesome/free-regular-svg-icons';
import Tippy from '@tippyjs/react';
import LabeledCheckbox from './UtilComponents/LabeledCheckbox';

/**
 * Component which contains component of the add livestream popup.
 *
 * @param props - The props of the component.
 * @returns The jsx of the component.
 */
function PopUpContents(props: Props): ReactElement {
	const {ws, isConnected} = useWebSocket();
	useEffect(() => {
		if (!isConnected) {
			console.error('Websocket is not connected');
		}
	}, [isConnected]);

	/**
	 * Breaks a file into chunks, so it can be sent to the backend.
	 * @param file - The file to be sent.
	 * @param videoService - The service that sends each chunks.
	 * @param techniqueCaptureIntervalsObject - The intervals of frame capture per technique.
	 * @param techniqueObjectsToDetectObject - The objects to be detected per technique.
	 */
	const sendVideoFile = async (
		file: File,
		videoService: VideoService,

		techniqueCaptureIntervalsObject: Record<string, number> | undefined,
		techniqueObjectsToDetectObject: Record<string, string[]> | undefined,
	): Promise<void> => {
		const s3Client = new S3({
			region: 'us-east-1',
			endpoint: 'https://ams3.digitaloceanspaces.com',
			credentials: {
				accessKeyId: 'DO00MQ986Z3LYYH9QYBY',
				secretAccessKey: 'obqFctMko25b61RpONdf1F+MI5qD8mKDvA0qaJyhaDU',
			},
		});

		try {
			const key = await uploadLargeFile(
				s3Client,
				'scenwise-computer-vision',
				file,
			);
			videoService.addVideoS3(
				key,
				livestreamName!,
				geofenceFlag,
				techniques.map(x => x.id),
				techniqueCaptureIntervalsObject,
				undefined,
				techniqueObjectsToDetectObject,
			);
		} catch (error) {
			console.error('Upload error:', error);
			console.error('Trying to upload again without multipart...');
			try {
				await uploadToS3(s3Client, file);
			} catch (error) {
				console.error('Upload error:', error); // Try uploading again twice; if failed, tell user
			}
		}
	};

	const uploadToS3 = async (s3Client: S3, file: File): Promise<void> => {
		const key = `${file.name}`;
		const bucketName = 'scenwise-computer-vision';

		await uploadFileToS3(s3Client, file, bucketName, key);
	};

	const uploadLargeFile = async (
		s3Client: S3,
		bucketName: string,
		file: File,
	): Promise<string> => {
		let uploadId;

		const key = 'videos/' + Date.now().toString() + '/' + file.name;

		try {
			const multipartUpload = await s3Client.send(
				new CreateMultipartUploadCommand({
					Bucket: bucketName,
					Key: key,
				}),
			);
			uploadId = multipartUpload.UploadId;

			const uploadPromises = [];

			const partSize = 5 * 1024 * 1024; // 25MB

			const numParts = Math.ceil(file.size / partSize);

			// Upload each part.
			for (let i = 0; i < numParts; i++) {
				const start = i * partSize;
				const end = start + partSize;
				const fileSlice = file.slice(start, end);
				uploadPromises.push(
					s3Client
						.send(
							new UploadPartCommand({
								Bucket: bucketName,
								Key: key,
								UploadId: uploadId,
								Body: fileSlice,
								PartNumber: i + 1,
							}),
						)
						.then(d => {
							console.log('Part', i + 1, 'uploaded');
							return d;
						}),
				);
			}

			const uploadResults = await Promise.all(uploadPromises);

			const response = await s3Client.send(
				new CompleteMultipartUploadCommand({
					Bucket: bucketName,
					Key: key,
					UploadId: uploadId,
					MultipartUpload: {
						Parts: uploadResults.map(({ETag}, i) => ({
							// eslint-disable-next-line @typescript-eslint/naming-convention
							ETag,
							PartNumber: i + 1,
						})),
					},
				}),
			);

			console.log('Multipart upload complete:', response);

			return key;
		} catch (err) {
			console.error(err);

			if (uploadId) {
				const abortCommand = new AbortMultipartUploadCommand({
					Bucket: bucketName,
					Key: key,
					UploadId: uploadId,
				});

				await s3Client.send(abortCommand);
			}

			return '';
		}
	};

	const uploadFileToS3 = async (
		s3Client: S3,
		file: File,
		bucketName: string,
		key: string,
	): Promise<void> => {
		const date = Date.now();
		const uploadParams = {
			Bucket: bucketName,
			Key: 'videos/' + date.toString() + '/' + key,
			Body: file,
			ContentType: 'video/mp4',
		};

		console.log('Uploading file to S3:', uploadParams);

		try {
			const command = new PutObjectCommand(uploadParams);
			const response = await s3Client.send(command);
			console.log('File uploaded successfully:', response);
		} catch (error) {
			console.error('Error uploading file:', error);
		}
	};

	/**
	 * Makes sure that all infromation needed to submit a source is there.
	 * Else shows errors.
	 * @returns - True if data is correct and available, false otherwise.
	 */
	const validateFields = (): boolean => {
		if (techniques.length === 0) {
			setTechniquesErrorMessage(
				'You must select at least one technique to apply!',
			);
			return false;
		}

		setTechniquesErrorMessage('');

		if (!livestreamName) {
			setNameErrorMessage('You must give a name!');
			return false;
		}

		setNameErrorMessage('');

		if (isVideoUrl) {
			if (!livestreamUrl) {
				setUrlErrorMessage('You must give a URL!');
				return false;
			}

			setUrlErrorMessage('');
		} else {
			if (!videoFile) {
				setFileErrorMessage('You must select a file!');
				return false;
			}

			setFileErrorMessage('');
		}

		return true;
	};

	/**
	 * Prepares the source indicate dby the user to be sent to the backend.
	 */
	const prepareVideoData = (): void => {
		if (!(ws && validateFields())) {
			return;
		}

		const videoService = new VideoService(ws);

		const transformedTechniqueCaptureIntervals = new Map(
			Array.from(techniqueCaptureIntervals, ([technique, number]) => [
				technique.id,
				number,
			]),
		);

		const techniqueCaptureIntervalsObject = Array.from(
			transformedTechniqueCaptureIntervals,
		).reduce((obj: Record<string, number>, [key, value]) => {
			obj[key] = value;
			return obj;
		}, {});

		const transformedTechniqueObjectsToDetect = new Map(
			Array.from(techniqueObjectsToDetect, ([technique, objects]) => [
				technique.id,
				objects,
			]),
		);

		const techniqueObjectsToDetectObject = Array.from(
			transformedTechniqueObjectsToDetect,
		).reduce((obj: Record<string, string[]>, [key, value]) => {
			obj[key] = value;
			return obj;
		}, {});

		if (isVideoUrl) {
			if (movingCamera) {
				videoService.requestViewSelection(
					livestreamUrl!,
					livestreamName!,
					techniques.map(x => x.id),
					movingCameraCaptureInterval,
					movingCameraCaptureDuration,
					techniqueObjectsToDetectObject,
				);
				props.addStreamRequiringViewSelection(
					livestreamUrl!,
					livestreamName!,
					techniques.map(x => x.id),
					techniqueObjectsToDetectObject,
				);
			} else {
				videoService.addSource(
					livestreamUrl!,
					livestreamName!,
					isVideo,
					geofenceFlag,
					techniques.map(x => x.id),
					techniqueCaptureIntervalsObject,
					undefined,
					techniqueObjectsToDetectObject,
					captureLength,
				);
			}
		} else {
			sendVideoFile(
				videoFile!,
				videoService,
				techniqueCaptureIntervalsObject,
				techniqueObjectsToDetectObject,
			).catch(error => {
				console.error('Error sending video file:', error);
			});
		}
	};

	/**
	 * Submission of add livestream form.
	 *
	 * @param event - Event triggerred when submit button is selected.
	 * @param onClose - Function which determines closing behavior of pop-up.
	 */
	const handleSubmit = (
		event: React.MouseEvent<HTMLButtonElement>,
		onClose: () => void,
	): void => {
		event.preventDefault(); // Prevents refresh on submit

		prepareVideoData();
		props.setSourceName(livestreamName ?? '');
		props.setTechniques(techniques);
		onClose();
	};

	const [videoFile, setVideoFile] = useState<File | undefined>(undefined);
	const [isVideoUrl, setIsVideoUrl] = useState<boolean>(true);
	const [livestreamName, setLivestreamName] = useState<string | undefined>(
		undefined,
	);
	const [livestreamUrl, setLivestreamUrl] = useState<string | undefined>(
		undefined,
	);
	const [movingCamera, setMovingCamera] = useState(false);
	const [isVideo, setisVideo] = useState(false);
	const [techniques, setTechniques] = useState<Technique[]>([]);
	const [geofenceFlag, setGeofenceFlag] = useState(false);
	const [captureLength, setCaptureLength] = useState<number>(0);
	const [urlErrorMessage, setUrlErrorMessage] = useState<string>('');
	const [fileErrorMessage, setFileErrorMessage] = useState<string>('');
	const [nameErrorMessage, setNameErrorMessage] = useState<string>('');
	const [techniquesErrorMessage, setTechniquesErrorMessage]
        = useState<string>('');

	const [techniqueCaptureIntervals, setTechniqueCaptureIntervals] = useState<
	Map<Technique, number>
	>(new Map());
	const [techniqueObjectsToDetect, setTechniqueObjectsToDetect] = useState<
	Map<Technique, string[]>
	>(new Map());

	const [movingCameraCaptureInterval, setMovingCameraCaptureInterval]
        = useState<number>(5);
	const [movingCameraCaptureDuration, setMovingCameraCaptureDuration]
        = useState<number>(120);

	const handleMovingCameraCaptureIntervalChange = (
		e: React.ChangeEvent<HTMLInputElement>,
	): void => {
		setMovingCameraCaptureInterval(Number(e.target.value));
	};

	const handleMovingCaptureDurationChange = (
		e: React.ChangeEvent<HTMLInputElement>,
	): void => {
		setMovingCameraCaptureDuration(Number(e.target.value));
	};

	/**
	 * Handler for interacting with the checkbox indicating whether link is video or livestream.
	 */
	const handleSourceTypeChange = (): void => {
		setIsVideoUrl(!isVideoUrl);
	};

	/**
	 * Handler for interacting with the checkbox indicating whether link is video or livestream.
	 */
	const handleVideoChange = (): void => {
		setisVideo(!isVideo);
	};

	/**
	 * Handler for changing the capture length in the add livestream form.
	 *
	 * @param e - Change event.
	 */
	const handleCaptureIntervalChangeV2 = (
		e: React.ChangeEvent<HTMLInputElement>,
	): void => {
		const interval = parseFloat(e.target.value);
		if (!isNaN(interval)) {
			setTechniques([techniqueList.at(0)!]);
			setTechniqueCaptureIntervals(prevIntervals => {
				const newIntervals = new Map(prevIntervals);
				const technique: Technique = techniqueList.at(0)!; // TODO: Change this to the correct technique
				newIntervals.set(technique, interval);
				return newIntervals;
			});
		}
	};

	/**
	 * Handler for changing the capture length in the add livestream form.
	 *
	 * @param e - Change event.
	 */
	const handleCaptureLengthChange = (
		e: React.ChangeEvent<HTMLInputElement>,
	): void => {
		const captureLength = parseInt(e.target.value, 10);
		if (!isNaN(captureLength)) {
			setCaptureLength(captureLength);
		}
	};

	/**
	 * Handler for changing the name in the add livestream form.
	 *
	 * @param e - Change event.
	 */
	const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
		if (e.target.validity.valid) {
			setNameErrorMessage('');
			setLivestreamName(e.target.value);
		} else {
			setNameErrorMessage('Invalid Input');
		}
	};

	/**
	 * Handler for changing the URL in the add livestream form.
	 *
	 * @param e - Change event.
	 */
	const handleUrlChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
		const error = validateUrl(e);
		if (error === undefined) {
			setUrlErrorMessage('');
			setLivestreamUrl(e.target.value);
		} else {
			setUrlErrorMessage(error);
		}
	};

	const validateUrl = (
		e: React.ChangeEvent<HTMLInputElement>,
	): string | undefined => {
		if (!e.target.validity.valid) {
			return 'Please provide a valid URL.';
		}

		if (e.target.value.includes('www.youtube.com')) {
			return 'YouTube is currently not supported.';
		}

		return undefined;
	};

	/**
	 * Handler for changing the moving camera flag in the add livestream form.
	 */
	const handleMovingCameraChange = (): void => {
		setMovingCamera(!movingCamera);
	};

	/**
	 * Handler for changing the techniques.
	 *
	 * @param technique - The technique which changed.
	 * @param added - Whether the technique is added or not.
	 */
	const handleTechniques = (technique: Technique, added: boolean): void => {
		if (added) {
			setTechniques(prevTechniques => [...prevTechniques, technique]);
			setTechniqueCaptureIntervals(prevIntervals => {
				const newIntervals = new Map(prevIntervals);
				newIntervals.set(technique, 10);
				return newIntervals;
			});
			setTechniqueObjectsToDetect(prevObjects => {
				const newObjects = new Map(prevObjects);
				newObjects.set(technique, []);
				return newObjects;
			});
		} else {
			setTechniques(prevTechniques =>
				prevTechniques.filter(tech => tech !== technique),
			);
			setTechniqueCaptureIntervals(prevIntervals => {
				const newIntervals = new Map(prevIntervals);
				newIntervals.delete(technique);
				return newIntervals;
			});
			setTechniqueObjectsToDetect(prevObjects => {
				const newObjects = new Map(prevObjects);
				newObjects.delete(technique);
				return newObjects;
			});
		}
	};

	const handleCaptureIntervalChange = (
		technique: Technique,
		e: React.ChangeEvent<HTMLInputElement>,
	): void => {
		const interval = parseInt(e.target.value, 10);
		if (!isNaN(interval) && techniques.includes(technique)) {
			// Ensure the technique is still selected
			setTechniqueCaptureIntervals(prevIntervals => {
				const newIntervals = new Map(prevIntervals);
				newIntervals.set(technique, interval);
				return newIntervals;
			});
		}
	};

	const handleDetectableObjectChange = (
		technique: Technique,
		object: string,
		added: boolean,
	): void => {
		setTechniqueObjectsToDetect(prevObjects => {
			const newObjects = new Map(prevObjects);
			let currentObjects = newObjects.get(technique) ?? [];

			if (added && !currentObjects.includes(object)) {
				currentObjects.push(object);
			} else {
				currentObjects = currentObjects.filter(obj => obj !== object);
			}

			newObjects.set(technique, currentObjects);
			return newObjects;
		});
	};

	/**
	 * Updates the File attribute when a new file is uploaded.
	 * @param event - Event information when new file is uploaded.
	 */
	const handleVideoFileChange = (
		event: React.ChangeEvent<HTMLInputElement>,
	): void => {
		if (event.target.files) {
			setVideoFile(event.target.files[0]);
		}
	};

	/**
	 * Handler for changing the geofence flag.
	 */
	const handleGeofenceChange = (): void => {
		setGeofenceFlag(!geofenceFlag);
	};

	return (
		<form className={styles.Window}>
			<h1 className={styles.Title}>Add Source</h1>
			<LabeledCheckbox
				labelText='Video from URL'
				isCheckboxChecked={isVideoUrl}
				checkboxAriaLabel='SelectVideoUrlAsSource'
				onCheckboxSelectFunction={handleSourceTypeChange}
			/>
			<LabeledCheckbox
				labelText='Video file'
				isCheckboxChecked={!isVideoUrl}
				checkboxAriaLabel='SelectVideoFileAsSource'
				onCheckboxSelectFunction={handleSourceTypeChange}
			/>
			{isVideoUrl && (
				<div>
					<p className={styles.Text}>URL:</p>
					<input
						required
						type='url'
						className={styles.Input}
						aria-label='URL:'
						pattern='https://.*'
						onChange={handleUrlChange}
					/>
					<p className={styles.Error}>{urlErrorMessage}</p>
				</div>
			)}
			{!isVideoUrl && (
				<div>
					<p className={styles.Text}>Select Video File:</p>
					<input
						required
						type='file'
						className={styles.Input}
						aria-label='File:'
						onChange={handleVideoFileChange}
					/>
					<p className={styles.Error}>{fileErrorMessage}</p>
				</div>
			)}
			<p className={styles.Text}>Name:</p>
			<input
				required
				type='text'
				className={styles.Input}
				aria-label='Name:'
				onChange={handleNameChange}
			/>
			<p className={styles.Text}>Capture Interval (seconds):</p>
			<input
				required
				type='number'
				min='1'
				className={styles.Input}
				aria-label='Name:'
				onChange={e => {
					handleCaptureIntervalChangeV2(e);
				}}
			/>
			<p className={styles.Error}>{nameErrorMessage}</p>
			<p className={styles.Text}>Time to record (minutes):</p>
			<input
				required
				type='number'
				min='0'
				max='1440'
				className={styles.Input}
				aria-label='Name:'
				onChange={e => {
					handleCaptureLengthChange(e);
				}}
			/>
			<p className={styles.Error}>{nameErrorMessage}</p>
			{/*
<LabeledCheckbox
				labelText='Moving Camera'
				isCheckboxChecked={undefined}
				checkboxAriaLabel='Moving Camera:'
				onCheckboxSelectFunction={handleMovingCameraChange}
			/>
			<LabeledCheckbox
				labelText='Geo-fence'
				isCheckboxChecked={undefined}
				checkboxAriaLabel='Geo-fence'
				onCheckboxSelectFunction={handleGeofenceChange}
			/>
			{isVideoUrl && (
				<LabeledCheckbox
					labelText='Video'
					isCheckboxChecked={undefined}
					checkboxAriaLabel='Video'
					onCheckboxSelectFunction={handleVideoChange}
				/>
			)}

            */}

			{movingCamera && (
				<>
					<p className={styles.Text}>
						Moving Camera Capture Interval (seconds):
					</p>
					<input
						type='number'
						value={movingCameraCaptureInterval}
						min={0}
						className={styles.Input}
						aria-label='Frame Capture Frequency:'
						onChange={handleMovingCameraCaptureIntervalChange}
					/>

					<p className={styles.Text}>
						Moving Camera Capture Duration (seconds):
					</p>
					<input
						type='number'
						value={movingCameraCaptureDuration}
						min={0}
						className={styles.Input}
						aria-label='Frame Capture Window:'
						onChange={handleMovingCaptureDurationChange}
					/>
					<Tippy content='To process a moving camera, you need to first select a camera view. If you input a frequency of 5 and a duration of 60, you will be able to choose a view from frames that are generated at 5 second intervals for a duration of 60 seconds.'>
						<FontAwesomeIcon
							icon={faQuestionCircle}
							style={{marginLeft: '3px'}}
						/>
					</Tippy>
				</>
			)}
			{/*

<ChecklistContainer
				data={techniqueList}
				title='Choose technique'
				aria-label='Selected Techniques:'
				renderExtra={(technique: Technique) =>
					techniques.includes(technique) ? (
						<>
							{technique.requiresObjectTypes && (
								<ObjectsDetectContainer
									data={detectableObjects}
									title='apply on:'
									aria-label='apply on:'
									onSelect={(object, added) => {
										handleDetectableObjectChange(
											technique,
											object,
											added,
										);
									}}
								/>
							)}
							{technique.customizableRetentionPeriod && (
								<div
									style={{
										display: 'flex',
										alignItems: 'center',
									}}
								>
									<span style={{margin: '0 10px'}}>
										every
									</span>
									<input
										type='number'
										aria-label='Capture Interval:'
										min='1'
										defaultValue='10'
										onChange={e => {
											handleCaptureIntervalChange(
												technique,
												e,
											);
										}}
									/>
									<span style={{margin: '0 10px'}}>
										seconds
										<Tippy content='For example: input 5 if you want the technique to be applied every 5 seconds.'>
											<FontAwesomeIcon
												icon={faQuestionCircle}
												style={{marginLeft: '3px'}}
											/>
										</Tippy>
									</span>
								</div>
							)}
						</>
					) : undefined}
				onSelect={handleTechniques}
			/>

            */}
			<p className={styles.Error}>{techniquesErrorMessage}</p>
			<div className={styles.ButtonsDiv}>
				<button
					type='button'
					id='Close'
					className={buttons.Button}
					onClick={props.onCloseProp}
				>
					Close
				</button>
				<button
					type='submit'
					className={buttons.Button}
					disabled={!isConnected}
					onClick={e => {
						handleSubmit(e, props.onCloseProp);
					}}
				>
					Add
				</button>
			</div>
		</form>
	);
}

export default PopUpContents;
