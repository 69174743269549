import React, {useEffect, useState, type ReactElement} from 'react';
import {useWebSocket} from '../api/AGWContext';
import VideoService from '../api/VideoService';
import stylesCommon from '../resources/styles/ComponentSpecific/MainBody.module.css';
import stylesSources from '../resources/styles/ComponentSpecific/VideoSources.module.css';
import {type SetSourcesProps} from './ArgumentTypes/SetSourcesProps';

/**
 * React component containing elements of component for searching for a livestream.
 *
 * @param props - The props of the component, input parameters to the component.
 * @returns React component containing elements of livestream component.
 */
function SearchLivestreamComponent(props: SetSourcesProps): ReactElement {
	const {ws, isConnected} = useWebSocket();
	useEffect(() => {
		if (!isConnected) {
			console.error('Websocket is not connected');
		}
	}, [isConnected]);
	const [livestreamNames, setLivestreamNames] = useState<string[]>(['{Please select a source}']);

	useEffect(() => {
		setLivestreamNames(props.allStreams);

		if (props.sourceName === 'None' && props.allStreams.length > 1) {
			props.setSourceName(props.allStreams.values().next().value as string);
		}
	}, [props.allStreams]);

	const handleLivestreamChange = (
		event: React.ChangeEvent<HTMLSelectElement>,
	): void => {
		if (ws) {
			const videoService = new VideoService(ws);
			const selectedLivestream: string = event.target.value;

			if (selectedLivestream === '') {
				return;
			}

			props.setSourceName(selectedLivestream);
			videoService.changeView(selectedLivestream);
		}
	};

	return (
		<div id='search_livestream' className={stylesSources.SearchDiv}>
			<p className={stylesCommon.Text}>
				Select which video stream you would like to view
			</p>
			<div id='search_bar'>
				<select
					value={props.sourceName || 'Select a livestream'}
					className={stylesSources.DropDown}
					disabled={!isConnected}
					onChange={handleLivestreamChange}
				>
					{livestreamNames && livestreamNames.length > 0 ? (
						livestreamNames.map((name: string) => (
							<option key={name} value={name}>
								{name}
							</option>
						))
					) : (
						<option disabled>Please add sources first</option>
					)}
				</select>
			</div>
		</div>
	);
}

export default SearchLivestreamComponent;
